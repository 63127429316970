<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import appConfig from "@/app.config";

/**
 * Customers component
 */
export default {
  page: {
    title: "Customers",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      customers: [],
      gender: 0,
      date: 0,
      limit: 20,
      page: 1,
      selectedFilter: null,
      searchMode: false,
      searchModel: "",
      tot_pages: 0,
      title: "Customers",
      items: [
        {
          text: "Ecommerce",
          href: "/"
        },
        {
          text: "Customers",
          active: true
        }
      ]
    };
  },
  methods: {
    changeFilter() {
      if (this.selectedFilter == "gender") {
        this.gender = true;
        this.date = false;
        return;
      }
      if (this.selectedFilter == "date") {
        this.gender = false;
        this.date = true;
        return;
      }
      this.gender = false;
      this.date = false;
    },
    search() {
      if (this.searchModel == "") {
        this.cancelSearchMode();
        return;
      }
      this.searchMode = true;
      this.tot_pages = 0;
      this.http
        .post("users/customers/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page
        })
        .then(res => {
          if (res.status) {
            this.customers = res.data;
          }
        });
    },
    cancelSearchMode() {
      this.searchModel = "";
      this.searchMode = false;
      this.get(this.page);
    },
    get(page) {
      this.http
        .post("users/customers", { limit: this.limit, page })
        .then(res => {
          if (res.status) {
            this.customers = res.data;
            this.page = page;
            this.tot_pages = Math.ceil(res.tot / this.limit);
          }
        });
    }
  },
  created() {
    this.get(1);
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-4">
                <div class="search-box me-2 mb-2 d-inline-block">
                  <div class="position-relative">
                    <input
                      @keyup="search()"
                      v-model="searchModel"
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                    />
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-8">
                <div class="text-sm"></div>
              </div>
              <!-- end col-->
            </div>

            <div class="table-responsive">
              <table
                class="table table-centered table-nowrap table-striped table-hover align-middle"
              >
                <thead>
                  <tr class="text-light" style="background-color: #2a3042 !important">
                    <th>#</th>
                    <th>{{ $t("customers.first_name") }}</th>
                    <th>{{ $t("customers.last_name") }}</th>
                    <th>{{ $t("customers.phone_email") }}</th>
                    <th>{{ $t("customers.country") }}</th>
                    <th>{{ $t("customers.city") }}</th>
                    <th>{{ $t("customers.total_orders") }}</th>
                    <th>{{ $t("customers.created") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(customers, index) in customers" :key="customers.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ customers.first_name }}</td>
                    <td>{{ customers.last_name }}</td>
                    <td>
                      <p class="mb-1">{{ customers?.user?.tel }}</p>
                      <p class="mb-0">{{ customers?.email }}</p>
                    </td>
                    <td>
                      {{
                      $i18n.locale == "ar"
                      ? customers?.city?.country?.name
                      ? customers?.city?.country?.name
                      : customers?.city?.country?.name_en
                      : customers?.city?.country?.name_en
                      ? customers?.city?.country?.name_en
                      : customers?.city?.country?.name
                      }}
                    </td>
                    <td>
                      {{
                      $i18n.locale == "ar"
                      ? customers?.city?.name
                      ? customers?.city?.name
                      : customers?.city?.name_en
                      : customers?.city?.name_en
                      ? customers?.city?.name_en
                      : customers?.city?.name
                      }}
                    </td>
                    <td>{{ customers?.user?.orders?.length }}</td>
                    <td>{{ customers?.created.split("T")[0] }}</td>
                    <td class="d-flex">
                      <router-link
                        class="btn btn-success mx-1"
                        :to="'/user-profile/' + customers?.user?.id"
                      >{{ $t("popups.profile") }}</router-link>
                      <router-link
                        class="btn btn-success mx-1"
                        :to="'/customer-profile/' + customers.id"
                      >{{ $t("popups.more") }}</router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ul class="pagination pagination-rounded justify-content-end mb-2">
              <li class="page-item" :class="{ disabled: page == 1 }">
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(page - 1)"
                  aria-label="Previous"
                >
                  <i class="mdi mdi-chevron-left"></i>
                </a>
              </li>
              <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
                <a class="page-link" href="javascript: void(0);" @click="get(p)">{{ p }}</a>
              </li>

              <li class="page-item" :class="{ disabled: page == tot_pages }">
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(page + 1)"
                  aria-label="Next"
                >
                  <i class="mdi mdi-chevron-right"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
